import { registerApplication, start, navigateToUrl, getMountedApps } from "single-spa";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { constructApplications, constructRoutes, constructLayoutEngine } from "single-spa-layout";
import microfrontendLayout from "./microfrontend-layout.html";

import loader from "./html/loader.html";
import error from "./html/error.html";
import { EventHint } from "@sentry/react";
import { PortalSession } from "@irata/common";

Sentry.init({
  dsn: "https://6dbf701ec13245d39fdc4a5d88d3348d@o1059128.ingest.sentry.io/4504922770243584",
  integrations: [new BrowserTracing()],
  tracesSampleRate: 0.1,
  environment: process.env.SENTRY_ENV,
  denyUrls: [/https:\/\/translate.googleapis.com/],
  beforeSend(event, hint: EventHint) {
    if (hint.originalException === "Timeout" || hint.originalException === "Timeout (b)") return null; //IP-176
    const error: any = hint.originalException;
    if (error && error.message && error.message.match(/LOADING_SOURCE_CODE/i)) {
      event.fingerprint = ["LOADING_SOURCE_CODE"];
      event.level = "info";
    }
    return event;
  },
  enabled: process.env.SENTRY_ENV !== "local"
});

/* session_expired event is broadcast after any 401 */
window.addEventListener("irata:session_expired", (evt: Event) => {
  if (!getMountedApps().includes("@irata/login")) {
    navigateToUrl("/login?e=session_expired");
  }
});

window.addEventListener("single-spa:routing-event", (evt: CustomEvent) => {
  try {
    if (window.hasOwnProperty("plausible")) {
      const url = window.location.href;
      //group IDs to make analytics more useful
      const redactedUrl = url.replace(/\/\d+/g, "/ID");
      const data: any = { u: redactedUrl + window.location.search, props: {} };
      if (window.hasOwnProperty("iosRoles")) {
        // @ts-ignore
        data.props = { roles: window.iosRoles };
      }

      const googtrans_matches = document.cookie.match("(^|;) ?googtrans=([^;]*)(;|$)");
      const lang = (googtrans_matches && googtrans_matches[2].split("/")[2]) || "en";
      data.props.lang = lang;
      // @ts-ignore
      window.plausible("pageview", data);
    }
  } catch (e) {}
});

PortalSession.setup(process.env.PUSHER_KEY, process.env.SENTRY_ENV === "local");

const data = {
  loaders: {
    loader: loader
  },
  errors: {
    error: error
  },
  props: {}
};
const routes = constructRoutes(microfrontendLayout.replace("$YEAR", new Date().getFullYear().toString()), data);
const applications = constructApplications({
  routes,
  loadApp({ name }) {
    return System.import(name);
  }
});
const layoutEngine = constructLayoutEngine({ routes, applications });

applications.forEach(registerApplication);
layoutEngine.activate();
start();
